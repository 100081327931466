import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { tickAction } from '../actions/gameActions';



const GameTimer = (props) => {
    useEffect(() => {
        const timer = setTimeout(() => props.tick(), 1000);

        return () => clearTimeout(timer);
    });

    return (<div></div>)
}

const mapStateToProps = (state, ownProps) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {
        tick: () => {dispatch(tickAction());}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameTimer);