import React from 'react';
import './App.css';

import Snack from './components/Snack';
import Router from './components/Router';


import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

const gameStore = createStore(rootReducer, applyMiddleware(thunk));

class App extends React.Component {
  render() {
    return (
      <Provider store={gameStore}>
        <Router />
        <Snack />
      </Provider>
    );
  }
}


export default App;