import { combineReducers } from 'redux';
import gameReducer from './gameReducer';
import historyReducer from './historyReducer';
import statReducer from './statReducer';
import contactUsReducer from './contactUsReducer';
import {configReducer} from './configReducer';

const reducer = combineReducers({
    config: configReducer,
    game: gameReducer,
    history: historyReducer,
    stat: statReducer,
    contactUs: contactUsReducer
});

export default reducer;