import React from 'react';

import Home from '../components/Home';
import About from '../components/About';
import Contact from '../components/Contact';
import Login from '../components/Login';

import {Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';

import { appLoadAction } from '../actions/statActions';
import { configGetAction } from '../actions/configActions';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { cyan,pink } from '@material-ui/core/colors';

import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import { createStore, applyMiddleware } from 'redux';
import {BrowserRouter} from 'react-router-dom';

const gameStore = createStore(rootReducer, applyMiddleware(thunk));

const theme = createMuiTheme(
  {
    palette: {
      primary: cyan,
      secondary: pink,
    },
  }
);

/*
export default function Palette() {
  return (
    <ThemeProvider theme={theme}>
      <Button color="primary">Primary</Button>
      <Button color="secondary">Secondary</Button>
    </ThemeProvider>
  );
}*/
class Router extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    this.props.appLoad();
    this.props.configGet();
  }

  render() {
    return (
        <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/login" component={Login} />
            <Route path="/" exact component={Home} />
          </Switch>
        </ThemeProvider>
        </BrowserRouter>
    );
  }
}


const mapStateToProps = (state) => {
  return {
  }

}


const mapDispatchToProps = (dispatch) => {
  return {
    appLoad: () => { dispatch(appLoadAction())},
    configGet: () => { dispatch(configGetAction())}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);