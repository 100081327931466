import React from 'react';

import Box from '@material-ui/core/Box';
import MainNavbar from '../view/main_navbar.js'
import { Typography, Button, TextField, Link } from '@material-ui/core';

class AboutMain extends React.Component {
    render() {
        return (
            <Box height="60vh" display="flex">
                <Typography variant='h6' >
                    Math Boss is a creation of Tau Ceti Software. Its mission is to empower everyone to build up their basic math skills.
                    We will assist you in making your addition, subtraction, multiplication and division skills and speed
                    to be at Boss Level.
                    <p />
                    We'd love to hear from you. If you wish to provide any feedback please do so using the
                    <Link href="/contact" > feedback form</Link>

                </Typography>
            </Box>
        )
    }

}
class About extends React.Component {
    render() {
        return (
            <div>
                <MainNavbar />
                <AboutMain />
            </div>
        )
    }
}

export default About;