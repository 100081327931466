import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import GameTimer from './GameTimer';
import {appLoadAction} from '../actions/statActions';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';



class Score extends React.Component {
    render() {
        return (
        <Button> Score:{this.props.score} </Button>
        )
    }
}

class NetStatus extends React.Component {
    load() {
        console.log("Reconnecting");
        this.props.appLoad();
    }

    render() {
        const color = (this.props.online)?'default': 'secondary';
        return (
            <Button color={color} onClick={this.load.bind(this)}>
                {(this.props.online)? 'online': 'offline'}
            </Button>
        )
    }
}

class Togo extends React.Component {
    render() {
        return (
        <Button> {this.props.togo} Remaining </Button>
        )
    }
}

class HighScore extends React.Component {
    render() {
        var high = this.props.highScore;
        var newHigh = false;
        if (this.props.score > high) {
            high = this.props.score;
            newHigh = true;
        }
        return (
            <Button onClick={this.props.displayScoreHistory}>
                HighScore:{high}
            </Button>
            )
        }
}



class Time extends React.Component {
    render() {
        let timeString;
        let gameTimer = '';
        if (this.props.finished) {
            timeString = "Finished";
        } else if (this.props.start_time) {
            let elapsed = this.props.now - this.props.start_time;
            let minutes = (Math.floor(elapsed/60)).toString().padStart(2, "0");
            let secs = (elapsed % 60).toString().padStart(2, "0");
            timeString = "TIME " + minutes + ":" + secs;
            gameTimer = <GameTimer />
        } else {
            timeString = "Not Started";
        }
        return (
            <div>
                <Typography> {timeString} </Typography>
                {gameTimer}
            </div>
            )
        }
}

class ScoreHistory extends React.Component {
    render() {
        let topten = [...this.props.scoreHistory];
        topten.sort(function(a, b){return b-a});
        topten.splice(5);


        return (
            <Dialog open={this.props.show}>
            <DialogTitle>
              High Scores
            </DialogTitle>
            <DialogContent>
                {topten.map((score, i) => (<DialogContentText key={i} > {i+1}: {score} </DialogContentText>))}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.hideScoreHistory} color="primary" autoFocus>
               Done
              </Button>
            </DialogActions>
          </Dialog>

        )
    }
}

class GameFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showScores: false
        };
    }

    displayScoreHistory() {
        this.setState({showScores: true});
    }

    hideScoreHistory() {
        this.setState({showScores: false});
    }

    render() {
        return (
        <AppBar position="static" style={{height: this.props.height}}>
            <ScoreHistory
                show={this.state.showScores}
                scoreHistory={this.props.scoreHistory}
                hideScoreHistory={this.hideScoreHistory.bind(this)}
            />
            <Toolbar>
                <Grid
                justify="space-between"
                container
                >
                    <Togo togo={this.props.togo} />
                    <Score score={this.props.score} />
                    <Time
                        now={this.props.now}
                        start_time={this.props.start_time}
                        finished={this.props.finished}
                    />
                    <NetStatus
                        online={this.props.online}
                        appLoad={this.props.appLoad}
                    />
                    <HighScore
                        highScore={this.props.highScore}
                        score={this.props.score}
                        displayScoreHistory={this.displayScoreHistory.bind(this)}
                    />
                </Grid>
            </Toolbar>
        </AppBar>
        )
    }
  }



const mapStateToProps = (state) => {
    let start_time = null;
    let score = 0;
    let togo = null;
    let now = null;
    let finished = false;
    if (state.game) {
        start_time = state.game.time_started;
        now = state.game.now;
        score = state.game.score;
        togo = state.game.togo;
        finished = state.game.finished;
    }
    return {
        start_time: start_time,
        now: now,
        score: score,
        togo: togo,
        highScore: state.history.highScore,
        scoreHistory: state.history.scoreHistory,
        finished: finished,
        online: state.stat.online
    }

}


const mapDispatchToProps = (dispatch) => {
  return {
    appLoad: () => { dispatch(appLoadAction())},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameFooter);