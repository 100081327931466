import {
    CONFIG_CHANGE_OPER,
    CONFIG_GET_SUCCESS,
    CONFIG_GET_FAILURE
} from '../constants/ActionTypes';

class GameConfig {
    constructor(grid_size = 9, operation = '+') {
        this.grid_size = grid_size; 
        this.operation = operation;
    }

    get_grid_size() {
        return this.grid_size;
    }

    get_operation() {
        return this.operation;
    }
}



const configReducer = (state = null, action)  => {
    let newState;

    if (state == null) {
        // initialize it
        state = new GameConfig();
    }

    switch(action.type) {
        case CONFIG_CHANGE_OPER:
            let {oper} = action;
            newState = new GameConfig(state.grid_size, oper)
            return newState;

        case CONFIG_GET_SUCCESS:
            let {response} = action;
            newState = new GameConfig(response.data.grid_size, state.get_operation())
            return newState;

        case CONFIG_GET_FAILURE:
            // TODO: Fix how to log this
            // console.log ("Config GET Failed");
            return state;

        default:
            return state;
    }
}

export { configReducer, GameConfig };