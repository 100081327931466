
import axios from 'axios';
import {apiurl_config} from '../requests/api_base';
import * as types from '../constants/ActionTypes';



function getConfig() {
    let payload = {};
    let api = apiurl_config();

    return axios.get(api, {
        ...payload
    });
}

const configGetAction = () => {
    return (dispatch, getState) => {
        return getConfig()
        .then( (response) => {
            dispatch({
                type: types.CONFIG_GET_SUCCESS,
                response
            })
        })
        .catch( (error) => {
            dispatch({
                type: types.CONFIG_GET_FAILURE,
                error
            })
        })
    }
};

const configChangeOperAction = (oper) => {
    return {
            type: types.CONFIG_CHANGE_OPER,
            oper
    }
};


export {
    configChangeOperAction,
    configGetAction,
}
