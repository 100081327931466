import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { createGameAction } from '../actions/gameActions';
import { configChangeOperAction } from '../actions/configActions';
import { gameStartAction } from '../actions/statActions';


class OperButton extends React.Component {
    onClick() {
        this.props.changeOper(this.props.oper);
    }

    render() {
        let color = '';
        if (this.props.oper === this.props.selected) {
            color = "primary"
        } else {
            color = "default"
        }
        return (
            <Button
                variant="contained"
                color={color}
                onClick={this.onClick.bind(this)}
            >
                {this.props.oper}
            </Button>
        )
    }
}


class NewGameButton extends React.Component {
    onClick() {
        this.props.createGame();
    }
    render() {
        return (
            <Button variant="contained" onClick={this.onClick.bind(this)} >New Game</Button>
        )
    }
}

class GameNavbar extends React.Component {
    createGame() {
        this.props.createGame(this.props.config);
        this.props.gameStart(this.props.config);
    }

    render() {  

      return (
          <AppBar position="static">
              <Toolbar>
                <Grid
                  justify="space-between"
                  container
                >
                    <NewGameButton createGame={this.createGame.bind(this)} />
                    <Grid >
                        <OperButton
                            oper='+'
                            selected={this.props.operation}
                            changeOper={this.props.changeOper}
                        />
                        <OperButton
                            oper='-'
                            selected={this.props.operation}
                            changeOper={this.props.changeOper}
                        />
                        <OperButton
                            oper='*'
                            selected={this.props.operation}
                            changeOper={this.props.changeOper}
                        />
                        <OperButton
                            oper='/'
                            selected={this.props.operation}
                            changeOper={this.props.changeOper}
                        />
                    </Grid>

                </Grid>
              </Toolbar>
        </AppBar>
      )
    }
  }

  const mapStateToProps = (state) => {
      return {
          operation: state.config.get_operation(),
          config: state.config
      }

  }


const mapDispatchToProps = (dispatch) => {
    return {
        createGame: (config) => { dispatch(createGameAction(config))},
        changeOper: (oper) => { dispatch(configChangeOperAction(oper))},
        gameStart: (config) => { dispatch(gameStartAction(config))},
    }
}

  export default connect(mapStateToProps, mapDispatchToProps)(GameNavbar);