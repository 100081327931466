import { GAME_COMPLETE } from '../constants/ActionTypes';
class ScoreHistory {
    constructor(highScore, scoreHistory) {
        this.highScore = highScore;
        this.scoreHistory = scoreHistory;
    }

    static newScore(history, score) {
        let scoreHistory = [...history.scoreHistory, score];
        let highScore = history.highScore;

        if (score > highScore) {
            highScore = score;
        }

        return new ScoreHistory(highScore, scoreHistory);
    }
}


const historyReducer = (state = null, action)  => {
    if (state == null) {
        state = new ScoreHistory(0, []);
    }

    switch(action.type) {
        case GAME_COMPLETE:
            let {score} = action;
            let newState = ScoreHistory.newScore(state, score);
            return newState;

        default:
            return state;
    }
}

export default historyReducer;