import React from 'react';

import Box from '@material-ui/core/Box';
import MainNavbar from '../view/main_navbar.js'
import { Typography, Button, TextField, Link } from '@material-ui/core';

class LoginMain extends React.Component {
    render() {
        return (
            <Box height="60vh" display="flex">
                <Typography variant='h6' >
                    This feature will be coming soon ! 
                    <p />
                    We'd love to hear from you. If you wish to provide any feedback please do so using the 
                    <Link href="/contact" > feedback form</Link>

                </Typography>
            </Box>
        )
    }

}
class Login extends React.Component {
    render() {
        return (
            <div>
                <MainNavbar />
                <LoginMain />
            </div>
        )
    }
}

export default Login;