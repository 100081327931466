import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';


class MainNavbar extends React.Component {
    render() {

      return (
        <AppBar position="static">
            <Toolbar>
              <Grid
                container
                alignItems='center'
                justify='center'>
                <Grid item xs={3}>
                  <Typography variant='h6'>
                    <Link component={RouterLink} to="/" color="inherit"> Home </Link>
                  </Typography>
                </Grid>
                <Grid item xs={6} >
                  <Typography variant="h3" color="inherit">
                  Math Boss
                  </Typography>
                  </Grid>
                <Grid item xs={3} justify="space-between" container>
                <Grid item>
                    <Typography variant='h6'>
                      <Link component={RouterLink} to="/login" color="inherit">Login</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h6'>
                      <Link component={RouterLink} to="/contact" color="inherit">Contact Us</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h6'>
                      <Link component={RouterLink} to="/about" color="inherit">About</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
        </AppBar>
      )
    }
  }
  export default MainNavbar;