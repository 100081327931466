import React from 'react';

import Box from '@material-ui/core/Box';
import MainNavbar from '../view/main_navbar.js'
import { Typography, Button, TextField, Paper, Grid } from '@material-ui/core';
import { contactUsAction } from '../actions/contactUsActions.js';
import { connect } from 'react-redux';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          name: "",
          email: "",
          feedback: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
      }

      handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    formSubmit(event) {
        event.preventDefault();
        this.props.contactUs(this.state.name, this.state.email, this.state.feedback);
    }

    render() {
        let submitting = false;

        return (
        <form noValidate onSubmit={this.formSubmit.bind(this)}>
        <Paper style={{ padding: 16 }}>
            <Typography variant="h5" >
                We would love to hear what's on your mind.
                Please use the form below to provide any feedback or questions you have.
            </Typography>

          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={6}>
                <TextField
                fullWidth
                required
                name="name"
                type="text"
                label="Your Name"
                variant="outlined"
                value={this.state.name}
                onChange={this.handleInputChange}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                name="email"
                fullWidth
                required
                type="email"
                label="Your Email"
                variant="outlined"
                value={this.state.email}
                onChange={this.handleInputChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                name="feedback"
                fullWidth
                required
                multiline
                type="text"
                label="feedback"
                variant="outlined"
                value={this.state.feedback}
                onChange={this.handleInputChange}
                />
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    onSubmit={this.formSubmit} >
                    Submit
                </Button>
            </Grid>

        </Grid>
        </Paper>
        </form>
        );
    }
}

class Contact extends React.Component {
    render() {
        return (
            <div>
                <MainNavbar />
                <Box >
                    <ContactForm contactUs={this.props.contactUs} />
                </Box>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }

}

const mapDispatchToProps = (dispatch) => {
  return {
    contactUs: (name, email, feedback) => { dispatch(contactUsAction(name, email, feedback))},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
