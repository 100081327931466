import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { setTestValAction } from '../actions/gameActions';

class GameTestEntry extends React.Component {
    onChange(e) {
        this.props.setTestVal(this.props.id, e.target.value);
    }

    render() {
        return (
            <Box width={80}>
            <TextField id={this.props.id}
                variant="outlined"
                size="small"
                value={this.props.val}
                inputProps={
                {maxLength: 3}
                }
                onChange={this.onChange.bind(this)}
                error={!this.props.correct}
            />
            </Box>
        )
    }
}

class GameOperation extends React.Component {

    render() {
        let left = this.props.left;
        let right = this.props.right;
        let oper = this.props.operation;
        return (
            <div id={this.props.id}>
                <Paper elevation={3} variant="outlined" >
                <Box display="flex" flextDirection="row">
                    <Box>
                        {left}{oper}{right}=
                    </Box>
                    <Box>
                    <GameTestEntry
                        id={this.props.id}
                        val={this.props.value}
                        correct={this.props.correct}
                        setTestVal={this.props.setTestVal}
                    />
                    </Box>
                </Box>
                </Paper>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let id = ownProps.id;
    let test = state.game.tests[id];
    let p = {
        ...ownProps,
        operation: state.game.config.get_operation(),
        left: test.operands[0],
        right: test.operands[1],
        expected: test.expected,
        value: test.value,
        correct: test.is_correct()
    }
    return p;
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTestVal: (id, val) => { dispatch(setTestValAction(id, val))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameOperation);