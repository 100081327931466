import {GameConfig} from './configReducer';
import { CREATE_GAME, TICK, SET_TEST_VAL } from '../constants/ActionTypes';


const createPlusOperands = () => {
    let left = Math.floor(Math.random() * 10);
    let right = Math.floor(Math.random() * 10);
    let res = left + right;

    return {operands: [left, right], expected: res}
}

const createTimesOperands = () => {
    let left = Math.floor(Math.random() * 10);
    let right = Math.floor(Math.random() * 10);
    let res = left * right;

    return {operands: [left, right], expected: res}
}

const createMinusOperands = () => {
    let res = Math.floor(Math.random() * 10);
    let right = Math.floor(Math.random() * 10);
    let left = res + right;

    return {operands: [left, right], expected: res}
}

const createDivOperands = () => {
    let res = Math.floor(Math.random() * 10);
    let right = 1+Math.floor(Math.random() * 9);
    let left = res * right;

    return {operands: [left, right], expected: res}
}

class Test {
    constructor(id, value, operation, operands, expected, score, minscore_at) {
        this.id = id.toString();
        this.value = value;
        this.operation = operation;
        this.operands = operands;
        this.expected = expected;
        this.minscore_at = minscore_at;
        this.score = score;
    }

    static newTest(id, operation) {
        let oper;
        if (operation === '+') {
            oper = createPlusOperands();
        } else if (operation === '*') {
            oper = createTimesOperands();
        } else if (operation === '-') {
            oper = createMinusOperands();
        } else if (operation === '/') {
            oper = createDivOperands();
        } else {
            //???
            oper = { operands: ['?', '?'], expected: '0'}
        }

        return new Test(id, '', operation, oper.operands, oper.expected, 0, 0);
    }

    static newVal(test, val) {
        let score = test.score;

        if (!test.is_correct() && (test.expected === parseInt(val))) {
            // time to score. Score is 500 base points + 100 extra points per sec away from
            // minscore_at for each correct answer.
            // minscore_at is currently initialized to <grid_size> secs into the future
            let timeScore = test.minscore_at - nowInSecs();
            score= 100 * (5 + ((timeScore > 0) ? timeScore : 0));
        }
        return new Test(
            test.id,
            val,
            test.operation,
            test.operands,
            test.expected,
            score,
            test.minscore_at);
    }

    static newMinscore(test, minscore_at) {
        return new Test(
            test.id,
            test.value,
            test.operation,
            test.operands,
            test.expected,
            test.score,
            minscore_at);
    }

    is_correct() {
        return (this.expected === parseInt(this.value));
    }
}


class GameState {
    constructor(config, time_started, now, score, togo, tests) {
        let grid = config.get_grid_size();
        let operation = config.get_operation();
        this.config = new GameConfig(grid, operation);
        this.time_started = time_started;
        this.now = now;
        this.score = tests.reduce((acum, test) => {return acum+test.score}, 0);
        this.togo = tests.reduce((acum, test) => {return acum+((test.is_correct())?0:1)}, 0);
        this.finished = (this.togo === 0);
        this.tests = tests;
    }

    static newGame(config) {
        let tests = [];
        for (let i = 0 ; i < config.get_grid_size() ; i++) {
            tests.push(Test.newTest(i, config.operation));
        }
        return new GameState(config, null, null, 0, config.get_grid_size(), tests);
    }

    static newTick(game) {
        return new GameState(
            game.config,
            game.time_started,
            nowInSecs(),
            game.score,
            game.togo,
            [...game.tests]);
    }

    static newVal(game, id, val) {
        let idx = parseInt(id);

        let tests = game.tests;
        let time_started = game.time_started;
        let now = game.now;
        if (time_started == null) {
            time_started = nowInSecs();
            now = time_started;

            // time has started = mark start time on tests for scoring 
            // difficulty should go up for harder operations (+,-,* -> /)
            let difficulty = 1;
            let minscore_at = time_started + (difficulty *game.config.get_grid_size());
            tests = tests.map((test) => {return Test.newMinscore(test, minscore_at)})
        }
        let test = Test.newVal(tests[idx], val);
        tests = [...tests.slice(0, idx), test, ...tests.slice(idx + 1)];

        let gs = new GameState(
            game.config,
            time_started,
            now,
            game.score,
            game.togo,
            tests);
        return gs;
    }
}


const nowInSecs = () => {
    return (Math.floor(Date.now() / 1000));
}

const gameReducer = (state = null, action)  => {
    let newState, newGame;

    switch(action.type) {

        case CREATE_GAME:
            let {config} = action;
            newState = GameState.newGame(config);

            return newState;

        case TICK:
            newState =  GameState.newTick(state);
            return newState;

        case SET_TEST_VAL:
            let {id, val} = action;

            newState = GameState.newVal(state, id, val);
            return newState;

        default:
            return state;
    }
}

export default gameReducer;