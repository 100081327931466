import axios from 'axios';

import {apiurl_appload, apiurl_gamestart} from '../requests/api_base';
import * as types from '../constants/ActionTypes';

function appload(info) {
    let payload = {
        'info': info
    }
    let api = apiurl_appload();

    return axios.post(api, {
        ...payload
    });
}

function gameStart(info) {
    let payload = {
        info
    }
    let api = apiurl_gamestart();

    return axios.post(api, {
        ...payload
    });
}

const appLoadAction = () => {
    return (dispatch, getState) => {
        return appload({'client_id': 'dontknow'})
        .then( (response) => {
            dispatch({
                type: types.STAT_APPLOAD_SUCCESS,
                response: response
            })
        })
        .catch( (error) => {
            dispatch({
                type: types.STAT_APPLOAD_FAILURE,
                error: error
            })
        });
    }
};

const gameStartAction = (config) => {
    return (dispatch, getState) => {
        return gameStart({
            'config': config,
            'client_id': 'unknown'
            })
        .then( (response) => {
            dispatch({
                type: types.STAT_GAMESTART_SUCCESS,
                response: response
            })
        })
        .catch( (error) => {
            dispatch({
                type: types.STAT_GAMESTART_FAILURE,
                error: error
            })
        });
    }
};


const appLoadIdleAction = () => {
    return {
        type: types.STAT_APPLOAD_IDLE
    }
};

const gameStartIdleAction = () => {
    return {
        type: types.STAT_GAMESTART_IDLE
    }
};

export {
    appLoadAction,
    appLoadIdleAction,
    gameStartAction,
    gameStartIdleAction,
}