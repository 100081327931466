import * as types from '../constants/ActionTypes';

export const createGameAction = (config) => {
    return {
        type: types.CREATE_GAME,
        config: config,
    }
};

export const tickAction = () => {
    return {
        type: types.TICK
    }
};


export const setTestValAction = (id, val) => {
    return {
        type: types.SET_TEST_VAL,
        id: id,
        val: val
    }
};

