import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import GameOperation from './GameOperation';
import { connect } from 'react-redux';
import { createGameAction } from '../actions/gameActions';
import { gameCompleteAction } from '../actions/historyActions';
import { gameStartAction } from '../actions/statActions';


class WinDialog extends React.Component {
    handleClose() {
      this.props.createGame();
    }

    render() {
      return (
        <Dialog open={this.props.on}>
          <DialogTitle>
            You Won!!!!
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose.bind(this)} color="primary" autoFocus>
              New Game
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
  }

class GameWindow extends React.Component {
    render() {

        return(
            <div>
                <GridList style={{background: 'lightyellow'}} cellHeight={50}  cols={3}>
                {this.props.game.tests.map((test) => (
                <GridListTile  key={test.id} cols={1}>
                    <GameOperation id={test.id} />
                </GridListTile>
                ))}
            </GridList>
          </div>
        )
    }
}

class AdWindow extends React.Component {
    render() {
        const styles = { background:{ background : 'pink' }};

        return(
            <div
            style={styles.background}
            >
                Ad Window
            </div>
        )
    }
}

class MainPane extends React.Component {
  createGame() {
    this.props.gameComplete(this.props.game.score);
    this.props.createGame(this.props.config);
    this.props.gameStart(this.props.config);
  }

  render() {
      let game_on = '';
      if (this.props.game !== null) {
          game_on = <GameWindow game={this.props.game} />
      }

      let win = false;
      if (this.props.game != null && this.props.game.finished) {
          win = true;
      }
      return (
        <div style={{marginTop: 10}}>
          <WinDialog createGame={this.createGame.bind(this)} on={win} />

          <Box display="flex">
              <Grid container>
              <Grid item sm={11}>
              {game_on}
              </Grid>
              <Grid item sm={1} >
              {/*<AdWindow />*/}
              </Grid>
              </Grid>
          </Box>
        </div>

      )
  }
}

const mapStateToProps = (state, ownProps) => {
    let p = {
        game: state.game,
        config: state.config
    }
    return p;
}

const mapDispatchToProps = (dispatch) => {
    return {
      createGame: (config) => { dispatch(createGameAction(config))},
      gameComplete: (score) => { dispatch(gameCompleteAction(score))},
      gameStart: (config) => { dispatch(gameStartAction(config))},

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPane);