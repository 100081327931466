import { CONTACT_US_SUCCESS, CONTACT_US_FAILURE } from '../constants/ActionTypes';

const initialState = {
    contacted: false,
    failed_contact: false
}

const contactUsReducer = (state = initialState, action)  => {

    switch(action.type) {
        case CONTACT_US_SUCCESS:
            const response = action.response;
            return {
                ...state,
                contacted: true,
                failed_contact: false
            };

        case CONTACT_US_FAILURE:
            const error = action.error;
            return {
                ...state,
                failed_contact: true,
                contacted: true
            };

        default:
            return state;
    }
}

export default contactUsReducer;