import React from 'react';

import MainNavbar from '../view/main_navbar.js'
import GameFooter from '../view/game_footer.js'
import GameNavbar from '../view/game_navbar.js'
import MainPane from '../view/main_pane.js'

class Home extends React.Component {
    render() {
        return (
            <div>
                <div style={{height: "calc(100vh - 60px)"}} >
                    <MainNavbar />
                    <GameNavbar />
                    <MainPane />
                </div>
                <GameFooter height="60px" />
            </div>
        )
    }
}

export default Home;