import React from 'react';

import { Snackbar } from '@material-ui/core';

import { connect } from 'react-redux';

import { appLoadIdleAction, gameStartIdleAction } from '../actions/statActions';

class OneSnack extends React.Component {

    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.props.open}
                    autoHideDuration={6000}
                    onClose={this.props.handleClose}
                    message={this.props.message}
                />
          </div>
        )
    }
}

class Snack extends React.Component {

    render() {

        return (
            <div>
            <OneSnack
                open={this.props.appload_sm==='fail'}
                message="Failed to Communicate with the Server. Game stats might not be available"
                handleClose={this.props.appLoadIdle.bind(this)}
            />
            <OneSnack
                open={this.props.gamestart_sm==='fail'}
                message="Failed to Communicate with the Server. Game stats might not be available"
                handleClose={this.props.gameStartIdle.bind(this)}
            />

            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        appload_sm: state.stat.appload_sm,
        gamestart_sm: state.stat.gamestart_sm
    }

}

const mapDispatchToProps = (dispatch) => {
  return {
    appLoadIdle: () => { dispatch(appLoadIdleAction())},
    gameStartIdle: () => { dispatch(gameStartIdleAction())},
}
}

export default connect(mapStateToProps, mapDispatchToProps)(Snack);

