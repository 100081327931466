import {
    STAT_APPLOAD_SUCCESS,
    STAT_APPLOAD_FAILURE,
    STAT_APPLOAD_IDLE,
    STAT_GAMESTART_SUCCESS,
    STAT_GAMESTART_FAILURE,
    STAT_GAMESTART_IDLE,
} from '../constants/ActionTypes';

const initialState = {
    appload_sm: 'idle', // idle -> sent -> {success | fail} -> idle
    gamestart_sm: 'idle', // idle -> sent -> {success | fail} -> idle
    online: false,
    last_err: ''
}

const statReducer = (state = initialState, action)  => {
    var r;

    switch(action.type) {
        case STAT_APPLOAD_IDLE:
            console.log("App load idle");
            return {
                ...state,
                appload_sm: 'idle'
            };

        case STAT_APPLOAD_SUCCESS:
            r = action.response;
            console.log("App load success:" + JSON.stringify(r));
            return {
                ...state,
                online: true,
                appload_sm: 'success'
            };

        case STAT_APPLOAD_FAILURE:
            r = action.error;
            console.log("App load failure:"+JSON.stringify(r));
            return {
                ...state,
                online: false,
                appload_sm: 'fail',
                last_err: r,
            };

        case STAT_GAMESTART_IDLE:
            console.log("Game Start idle");
            return {
                ...state,
                gamestart_sm: 'idle'
            };

        case STAT_GAMESTART_SUCCESS:
            r = action.response;
            console.log("Game Start success:" + JSON.stringify(r));
            return {
                ...state,
                online: true,
                gamestart_sm: 'success'
            };

        case STAT_GAMESTART_FAILURE:
            r = action.error;
            console.log("Game Start failure:"+JSON.stringify(r));
            return {
                ...state,
                online: false,
                gamestart_sm: 'fail',
                last_err: r,
            };

        default:
            return state;
    }
}

export default statReducer;
