import axios from 'axios';

import {apiurl_contactus} from '../requests/api_base';
import * as types from '../constants/ActionTypes';


function contactUs(name, email, feedback) {
    let payload = {
        name,
        email,
        feedback
    }
    let api = apiurl_contactus();

    return axios.post(api, {
        ...payload
    });
}

const contactUsAction = (name, email, feedback) => {
    return (dispatch, getState) => {
        return contactUs(name, email, feedback)
        .then( (response) => {
            dispatch({
                type: types.CONTACT_US_SUCCESS,
                response: response
            })
        })
        .catch( (error) => {
            dispatch({
                type: types.CONTACT_US_FAILURE,
                error: error
            })
        });
    }
};

export {
    contactUsAction
}