

const apiurl = () => {
    let base = new URL(window.location.href);

    if (base.hostname === 'localhost') {
        // when testing local, npm is running on 3000 while
        // heroku server is running on 5000
        base.port = 5000;
    }
    base.pathname = 'api/v1/';
    return base.href;
};

const apiurl_config = () => {
    let api = apiurl() + 'config';
    return api;
}

const apiurl_contactus = () => {
    let api = apiurl() + 'contactus';
    return api;
}

const apiurl_appload = () => {
    let api = apiurl() + 'stats/appload';
    return api;
}
const apiurl_gamestart = () => {
    let api = apiurl() + 'stats/gamestart';
    return api;
}

export {
    apiurl_appload,
    apiurl_config,
    apiurl_contactus,
    apiurl_gamestart
};

