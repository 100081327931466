// gameActions
export const CREATE_GAME = 'CREATE_GAME';
export const TICK = 'TICK';
export const SET_TEST_VAL = 'SET_TEST_VAL';

// historyActions
export const GAME_COMPLETE = 'GAME_COMPLETE';

// configActions
export const CONFIG_CHANGE_OPER = 'CONFIG_CHANGE_OPER';
export const CONFIG_GET_SUCCESS = 'CONFIG_GET_SUCCESS';
export const CONFIG_GET_FAILURE = 'CONFIG_GET_FAILURE';

// contactUsActions
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE';

// statActions

export const STAT_APPLOAD_SUCCESS = 'APP_LOAD_SUCCESS';
export const STAT_APPLOAD_FAILURE = 'APP_LOAD_FAILURE';
export const STAT_APPLOAD_IDLE = 'APP_LOAD_IDLE';
export const STAT_GAMESTART_SUCCESS = 'GAME_START_SUCCESS';
export const STAT_GAMESTART_FAILURE = 'GAME_START_FAILURE';
export const STAT_GAMESTART_IDLE = 'GAME_START_IDLE';

